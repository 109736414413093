import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./hooks/auth";

const ProtectedRoute = ({ children }) => {
  const { isAuthed, isInRole, logout } = useAuth();
  const location = useLocation();
  if (!isAuthed()) {
    let path = `/auth/login?redirect=${encodeURIComponent(location.pathname)}`;
    return <Navigate to={path} replace />;
  }
  if (!isInRole("Admin")) {
    alert("此帳號非管理人員，請重新登入");
    logout();
  }
  return children;
};

export default ProtectedRoute;