import { useEffect } from "react";
import style from "./App.module.scss";
import { Routes, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import store from "./store";
import { ThemeProvider, createTheme, makeStyles, StylesProvider } from '@material-ui/core/styles';

import Home from "./components/Home/Home.lazy";
import FinanceReport from "./components/FinanceReport/index.lazy";
import Login from "./components/Login/index.lazy";
import ProtectedRoute from "./ProtectedRoute";
import { AuthProvider } from "./hooks/auth";

const theme = createTheme();
function App() {


  return (
    <StylesProvider injectFirst>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <Routes>
              <Route path="/auth/login" element={<Login />} />

              <Route path="/" element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              } >
                <Route path="/financereport" element={<FinanceReport />} />

              </Route>


            </Routes>
          </AuthProvider>
        </ThemeProvider>
      </Provider>
    </StylesProvider>
  );
}

export default App;
